import jwt from "jsonwebtoken";
import api from "../api/api";
import publickey from "../api/publickey";

export const checkAuth = () => async (dispatch) => {
  try {
    if (localStorage.getItem("admin-auth")) {
      jwt.verify(localStorage.getItem("admin-auth"), publickey, async (err) => {
        if (!err) {
          return dispatch({
            type: "AUTH_ADMIN",
          });
        } else {
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
      });
    } else if (localStorage.getItem("user-auth")) {
      jwt.verify(localStorage.getItem("user-auth"), publickey, async (err) => {
        if (!err) {
          return dispatch({
            type: "AUTH_USER",
          });
        } else {
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
      });
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  } catch (error) {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
};

export const fetchDecoded = () => (dispatch) => {
  let token;
  if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
  } else if (localStorage.getItem("user-auth")) {
    token = localStorage.getItem("user-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, (err, decoded) => {
    if (!err) {
      try {
        return dispatch({
          type: "FETCH_DECODED",
          payload: decoded,
        });
      } catch (error) {
        return;
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};

export const fetchProfile = () => async (dispatch) => {
  let token;
  let type = null;
  if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
    type = "admin";
  } else if (localStorage.getItem("user-auth")) {
    token = localStorage.getItem("user-auth");
    type = "user";
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, async (err, decoded) => {
    if (!err) {
      try {
        const response = await api.get(`/api/${type}/${decoded.id}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        return dispatch({
          type: "FETCH_PROFILE",
          payload: response.data.data,
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};

export const fetchSettings = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
  } else if (localStorage.getItem("user-auth")) {
    token = localStorage.getItem("user-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, async (err, decoded) => {
    if (!err) {
      try {
        const response = await api.get(`/api/settings`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        dispatch({
          type: "FETCH_SETTINGS",
          payload: response.data.data,
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
        return dispatch({
          type: "CLEAR_DASHBOARD",
        });
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};

export const logout = () => async (dispatch) => {
  window.location.href = "/login";
  localStorage.clear();
  return dispatch({
    type: "AUTH_FAIL",
  });
};
