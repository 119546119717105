import { combineReducers } from "redux";
import authReducer from "./authReducer";
import decodedReducer from "./decodedReducer";
import profileReducer from "./profileReducer";
import settingsReducer from "./settingsReducer";
export default combineReducers({
  auth: authReducer,
  decoded: decodedReducer,
  profile: profileReducer,
  settings: settingsReducer,
});
