import React, { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import {
  checkAuth,
  fetchSettings,
  fetchDecoded,
  fetchProfile,
} from "./actions";
import PublicWrapper from "./Wrapper/Public/Wrapper";
import AdminWrapper from "./Wrapper/Admin/Wrapper";

const Home = lazy(() => import("./pages/Public/Home"));
const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Users = lazy(() => import("./pages/Users/Users"));
const Administrator = lazy(() => import("./pages/Administrator/Administrator"));
const Settings = lazy(() => import("./pages/Settings/Settings"));
const Futsal = lazy(() => import("./pages/Futsal/Futsal"));
const BookingOverView = lazy(() => import("./pages/Futsal/BookingOverView"));
const BookingTimeSlot = lazy(() => import("./pages/Futsal/BookingTimeSlot"));
const Photos = lazy(() => import("./pages/Futsal/Photos"));
const Rules = lazy(() => import("./pages/Futsal/Rules"));
const Pricing = lazy(() => import("./pages/Futsal/Pricing"));
const Holidays = lazy(() => import("./pages/Holidays/Holidays"));
const AdminAccountSettings = lazy(() =>
  import("./pages/AccountSettings/AccountSettings")
);
const About = lazy(() => import("./pages/Public/About"));
const Contact = lazy(() => import("./pages/Public/Contact"));
const PublicPricing = lazy(() => import("./pages/Public/Pricing"));
const PrivacyPolicy = lazy(() => import("./pages/Public/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("./pages/Public/RefundPolicy"));
const TermsAndConditions = lazy(() =>
  import("./pages/Public/TermsAndConditions")
);
const NotFound = lazy(() => import("./NotFound"));

const App = (props) => {
  const { checkAuth, fetchSettings, fetchDecoded, fetchProfile, auth } = props;

  useEffect(() => {
    checkAuth();
    if (auth) fetchData();
  }, [auth]);

  const fetchData = async () => {
    await Promise.all([fetchDecoded(), fetchProfile(), fetchSettings()]);
  };

  if (auth === 2) {
    //admin
    const ROUTES = [
      { path: "/", component: <Dashboard /> },
      { path: "/account", component: <AdminAccountSettings /> },
      { path: "/settings", component: <Settings /> },
      { path: "/users", component: <Users /> },
      { path: "/administrator", component: <Administrator /> },
      { path: "/holidays", component: <Holidays /> },
      { path: "/futsal", component: <Futsal /> },
      { path: "/futsal/booking-overview", component: <BookingOverView /> },
      {
        path: "/futsal/booking-overview/:start/:end",
        component: <BookingOverView />,
      },
      { path: "/futsal/booking-timeslot", component: <BookingTimeSlot /> },
      {
        path: "/futsal/booking-timeslot/:date",
        component: <BookingTimeSlot />,
      },
      { path: "/futsal/photos", component: <Photos /> },
      { path: "/futsal/rules", component: <Rules /> },
      { path: "/futsal/pricing", component: <Pricing /> },
    ];
    return (
      <AdminWrapper>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 300,
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<NotFound />} />
            {ROUTES.map((item) => (
              <Route
                key={item.path}
                path={item.path}
                exact
                element={item.component}
              />
            ))}
          </Routes>
        </Suspense>
      </AdminWrapper>
    );
  } else if (auth === 0) {
    //public
    const ROUTES = [
      { path: "/", component: <Home /> },
      { path: "/login", component: <Login /> },
      {
        path: "/about",
        component: <About />,
      },
      {
        path: "/contact",
        component: <Contact />,
      },
      {
        path: "/pricing",
        component: <PublicPricing />,
      },
      {
        path: "/privacy-policy",
        component: <PrivacyPolicy />,
      },
      {
        path: "/refund-policy",
        component: <RefundPolicy />,
      },
      {
        path: "/terms-and-conditions",
        component: <TermsAndConditions />,
      },
    ];
    return (
      <PublicWrapper>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 300,
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<NotFound />} />
            {ROUTES.map((item) => (
              <Route
                key={item.path}
                path={item.path}
                exact
                element={item.component}
              />
            ))}
          </Routes>
        </Suspense>
      </PublicWrapper>
    );
  } else {
    return (
      <div
        style={{
          marginTop: 300,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return { auth: state.auth };
};
export default connect(mapStateToProps, {
  checkAuth,
  fetchSettings,
  fetchDecoded,
  fetchProfile,
})(App);
