import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#454648",
    },
    secondary: {
      main: "#d11d1d",
    },
  },
  typography: {
    fontSize: 12,
  },
});
export default theme;

//black #535150
//primary dal #CA7DF9
//blue ang chi #6161F3
